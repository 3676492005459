/* Style the header with a grey background and some padding */
.header {
  overflow: hidden;
  background-color: #f1f1f1;
  padding: 20px 10px;
}

/* Style the header links */
.header a {
  float: left;
  color: black;
  text-align: center;
  padding: 12px;
  text-decoration: none;
  font-size: 18px;
  line-height: 25px;
  border-radius: 4px;
  margin-right: 2px;
  margin-left: 2px;
}

.header .active {
  background-color: #ddd;
  color: black;
}

/* Style the logo link (notice that we set the same value of line-height and font-size to prevent the header to increase when the font gets bigger */
.header a.logo {
  font-size: 25px;
  font-weight: bold;
}

/* Change the background color on mouse-over */
.header a:hover {
  background-color: #ddd;
  color: black;
}

/* Style the active/current link*/
.header span .active {
  background-color: dodgerblue;
  color: white;
}

/* Float the link section to the right */
.header-right {
  float: right;
}

/* login-page start */
.login__page {
  min-height: calc(100vh - 100px);
  display: flex;
  align-items: center;
  justify-content: center;
}
.login__form {
  border-radius: 12px;
  box-shadow: 0px 0px 30px #e7e7e7;
  width: 400px;
  max-width: calc(100% - 30px);
  margin: 0px auto;
}
.login__header {
  padding: 30px 30px;
  border-bottom: 1px solid #e3e3e3;
}
.login__header .title {
  font-size: 30px;
}
.login__header p {
  margin-bottom: 0px;
  font-size: 16px;
  color: grey;
}
.login__form .error {
  color: #721c24;
  margin-top: 5px;
}
/* login-page end */

.rdt_Table {
  width: calc(100% - 30px) !important;
  margin: 0px auto;
}
.rdt_Table .rdt_TableHead .rdt_TableCol {
  background-color: #1978b7;
  color: #ffffff;
  border-right: 1px solid #ffffff;
  padding: 10px 10px;
}
.rdt_Table .rdt_TableHead .rdt_TableCol:first-child {
  border-top-left-radius: 8px;
}
.rdt_Table .rdt_TableHead .rdt_TableCol:last-child {
  border-top-right-radius: 8px;
}
.rdt_Table .rdt_TableRow {
  border-bottom: none !important;
}
.rdt_Table .rdt_TableBody .rdt_TableCell {
  border-left: 1px solid #e1e1e1;
  border-bottom: 1px solid #e1e1e1;
  padding: 10px 10px;
}
.rdt_Table .rdt_TableBody .rdt_TableRow {
  transition: 0.5s;
}
/* .rdt_Table .rdt_TableBody .rdt_TableRow:hover{
  box-shadow:0px 0px 14px #d9d9d9;
  position: relative;
  z-index:2;
} */
.rdt_Table .rdt_TableBody .rdt_TableRow:last-child .rdt_TableCell {
  border-bottom: none;
}
.rdt_Table .rdt_TableBody .rdt_TableCell:last-child {
  border-right: 1px solid #e1e1e1;
}
.rdt_Table .rdt_TableBody .rdt_TableRow .rdt_TableCell div {
  white-space: normal;
}
.delete__btn {
  background-color: red;
  border: none;
  border-radius: 10px;
  padding: 8px 18px;
  color: white;
  transition: 0.5s;
  margin-right: 5px;
}
.table__buttons {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.table__buttons .edit__btn:nth-child(1) {
  margin-right: 10px;
}

.edit__btn {
  background-color: blue;
  border: none;
  border-radius: 10px;
  padding: 8px 18px;
  color: white;
  transition: 0.5s;
}
.delete__btn:hover {
  background-color: #d70b0b;
}
.edit__btn:hover {
  background-color: #0000ff;
}
.rdt_TableHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.rdt_TableHeader > div:nth-child(2) {
  position: static;
  transform: unset;
}
.rdt__table__main__wrapper {
  position: relative;
  margin-top: 20px;
}

.rdt__table__main__wrapper header {
  padding: 15px;
}
.rdt__table__main__wrapper .rdt_TableHeader {
  padding: 0px;
  padding: 30px 15px 20px 15px;
  min-height: unset;
}
.rdt__table__main__wrapper .button-add {
  background-color: #1b7ab4;
  border: none;
  border-radius: 10px;
  padding: 8px 18px;
  color: white;
  transition: 0.5s;
}
.rdt__table__main__wrapper .button-add {
  background-color: #0b5887;
}

.header__notification {
  padding: 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.header__notification .logo:hover {
  background-color: transparent;
}
.header__notification .s__Menu__Item__Link {
  border-radius: 0px;
  padding: 30px 0px;
  margin-right: 20px;
  position: relative;
}
.header__notification .s__Menu__Item__Link:hover {
  background-color: transparent;
  color: #1b7ab4;
}
.header__notification .s__Menu__Item__Link::before {
  content: "";
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 0px;
  height: 3px;
  transition: 0.5s;
  z-index: 3;
  background-color: #1b7ab4;
}
.header__notification .s__Menu__Item__Link.active {
  background-color: transparent;
  color: #1b7ab4;
}
.header__notification .s__Menu__Item__Link.active:before {
  width: 100%;
}
/* .rdt_Table .rdt_TableBody .rdt_TableRow:nth-child(even){
  background-color:#f5f5f5;
} */
/* modal css start */
.ip__Modal__Wrapper {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9;
  display: none;
}
.ip__Modal__Wrapper.small__without__HT__modal.show {
  display: flex;
}
.ip__Modal__Wrapper .ip__Modal__Overlay {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  background-color: #0009;
  pointer-events: none;
}
.ip__Modal__Wrapper .ip__Modal__ContentWrap {
  background-color: white;
  border-radius: 12px;
  position: relative;
  z-index: 2;
  width: 450px;
  max-width: calc(100% - 20px);
}
.ip__Modal__Wrapper .ip__Modal__Header {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  border: 1px solid grey;
  padding: 20px 24px;
  padding-right: 16px;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  background-color: white;
}
.ip__Modal__Wrapper .ip__Modal__Header .title {
  color: black;
  font-size: 22px;
  width: calc(100% - 32px);
}
.ip__Modal__Wrapper .ip__Modal__Header .i__Icon {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  padding: 0px;
  transition: 0.5s;
  cursor: pointer;
}
.ip__Modal__Wrapper .ip__Modal__Header .i__Icon:hover {
  background-color: #e1e1e1;
}
.ip__Modal__Wrapper .ip__Modal__Header .i__Icon svg {
  width: 100%;
  height: 100%;
}
.ip__Modal__Wrapper .ip__Modal__Header .i__Icon svg path {
  stroke: black;
}
.ip__Modal__Wrapper .ip__Modal__Body {
  padding: 30px;
  max-height: calc(100vh - 250px);
  overflow-y: auto;
}
.ip__Modal__Wrapper .ip__Modal__Footer {
  padding: 15px 24px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  border-top: 1px solid grey;
}
.ip__Modal__Wrapper .ip__Modal__Footer .i__Button {
  margin-right: 20px;
}
.ip__Modal__Wrapper .ip__Modal__Footer .i__Button:last-child {
  margin-right: 0px;
}

/* small-modal */
.ip__Modal__Wrapper.small__without__HT__modal .ip__Modal__ContentWrap {
  width: 450px;
  border-radius: 24px;
}
.ip__Modal__Wrapper.small__without__HT__modal .ip__Modal__Header {
  padding: 0px;
  background-color: transparent;
  border: none;
  justify-content: flex-end;
  padding-top: 8px;
  padding-right: 8px;
}
.ip__Modal__Wrapper.small__without__HT__modal .ip__Modal__Header .title {
  display: none;
}
.ip__Modal__Wrapper.small__without__HT__modal .ip__Modal__Body {
  padding-bottom: 10px;
  padding-top: 0px;
}
.ip__Modal__Wrapper.small__without__HT__modal .ip__Modal__Footer {
  justify-content: center;
  border-top: none;
  padding-bottom: 30px;
}
.ip__Modal__Wrapper.small__without__HT__modal .confirmation__icon {
  width: 64px;
  height: 64px;
  margin: 0px auto;
  border-radius: 50%;
  padding: 17px;
  margin-bottom: 20px;
}
.ip__Modal__Wrapper.small__without__HT__modal .confirmation__icon svg {
  width: 100%;
  height: 100%;
}
.ip__Modal__Wrapper.small__without__HT__modal .confirmation__image__wrapper {
  width: 70px;
  height: 70px;
  margin: 0px auto;
  margin-bottom: 15px;
  padding: 10px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f4f4f4;
}
.ip__Modal__Wrapper.small__without__HT__modal
  .confirmation__image__wrapper
  img {
  display: inline-block;
  max-width: 100%;
  width: auto;
}
.ip__Modal__Wrapper.small__without__HT__modal .confirmation__title {
  color: black;
  font-size: 24px;
  text-align: center;
  width: 300px;
  max-width: 100%;
  margin: 0px auto;
}
.ip__Modal__Wrapper .i__Button.secondary__Btn {
  background-color: #ebebeb;
  border: none;
  border-radius: 10px;
  padding: 8px 18px;
  color: black;
  transition: 0.5s;
}
.ip__Modal__Wrapper .i__Button.secondary__Btn:hover {
  background-color: #d9d9d9;
}
.ip__Modal__Wrapper .i__Button.delete__Btn {
  background-color: red;
  border: none;
  border-radius: 10px;
  padding: 8px 18px;
  color: white;
  transition: 0.5s;
}
.ip__Modal__Wrapper .i__Button.delete__Btn:hover {
  background-color: #c50808;
}
/* small-modal-end */
/* modal css end */

.add__user__form__wrapper {
  min-height: calc(100vh - 90px);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 40px 0px;
}

/* toast css start */
.toast__wrapper {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: rgb(217 235 245);
  z-index: 10;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 50px 10px 17px;
  border: 1px solid #1b7ab4;
  max-width: 400px;
  box-shadow: 0px 0px 20px #e5e5e5;
  color: #1b7ab4;
  font-size: 16px;
  display: none;
}
.toast__wrapper.show {
  display: flex;
}
.toast__wrapper .close__btn {
  width: 30px;
  height: 30px;
  background-color: transparent;
  border: none;
  transition: 0.5s;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 12px;
  border-radius: 50%;
}
.toast__wrapper .close__btn:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translatex(-50%) translateY(-50%) rotate(45deg);
  width: 12px;
  height: 2px;
  background-color: #000000;
}
.toast__wrapper .close__btn:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translatex(-50%) translateY(-50%) rotate(-45deg);
  width: 12px;
  height: 2px;
  background-color: #000000;
}
.toast__wrapper .close__btn:hover {
  background-color: #ffffff;
}
.toast__wrapper.error__toast {
  border: 1px solid red;
  background-color: #f7dcdc;
  color: red;
}
.toast__wrapper.success__toast {
  border: 1px solid green;
  background-color: #e3fbe3;
  color: green;
}
.toast__wrapper.warning__toast {
  border: 1px solid orange;
  background-color: #f5e8cf;
  color: black;
}
/* toast css end */

/* Mail Logs CSS */
.emailTable {
  width: 100%;
  border-collapse: collapse;
  border-radius: 10px;
  table-layout: fixed;
}
.emailTable th,
.emailTable td {
  border: 1px solid #e5e5e5;
  padding: 10px 15px;
}
.emailTable td p {
  margin-bottom: 5px;
  word-break: break-word;
}
.emailTable th {
  width: 300px;
  background-color: #f9f9f9;
}
.logBox {
  margin-top: 30px;
}
.logBox .title {
  font-size: 22px;
  margin-bottom: 12px;
}
.logBox ul li {
  margin-bottom: 6px;
}
.emailTable__heading {
  font-size: 20px;
  margin-bottom: 12px;
}

/* button loader css start */
.i__ButtonLoader {
  width: 0px;
  height: 0px;
  padding: 7px;
  border-radius: 50%;
  border: 3px solid rgba(255, 255, 255, 0.8);
  border-right: 3px solid #ffffff;
  position: relative;
  top: -2px;
  margin-right: 10px;
  margin-top: 2px;
  animation-name: i__ButtonLoader;
  animation-duration: 1s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}
@keyframes i__ButtonLoader {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
/* button loader css end */

.tableHeader {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.tableHeader .rightWrapper {
  display: flex;
  align-items: center;
}
.tableHeader .rightWrapper .button {
  flex-shrink: 0;
  margin-left: 15px;
}

.horizontal__tab {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  margin-bottom: 20px;
}
.horizontal__tab::before {
  content: "";
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: calc(100% + 40px);
  height: 1px;
  background-color: #e1e1e1;
  left: -20px;
}
.horizontal__tab .horizontal__tab__item {
  background-color: transparent;
  border: 1px solid #e1e1e1;
  border-bottom: none;
  margin-right: 15px;
  padding: 10px 20px;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}
.horizontal__tab .horizontal__tab__item.active {
  border: 1px solid #1978b7;
  border-bottom: none;
  background-color: #1978b7;
  color: #ffffff;
}
.horizontal__tab .horizontal__tab__item:last-child {
  margin-right: 0px;
}

.paginationWrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 15px 15px 10px 15px;
}
.paginationWrapper .perPageWrapper {
  display: inline-flex;
  align-items: center;
}
.paginationWrapper .perPageWrapper .label {
  margin-bottom: 0px;
  font-size: 14px;
  margin-right: 6px;
  display: inline-block;
}
.paginationWrapper .perPageWrapper .pageSelect {
  font-size: 14px;
  border: none;
  background-color: transparent;
}
.paginationWrapper .perPageWrapper .pageSelect:focus {
  outline: none;
  border: none;
}
.paginationWrapper .valueWrapper {
  font-size: 14px;
  margin-bottom: 0px;
  padding: 0px 20px;
}
.paginationWrapper .btnsGroup {
  display: flex;
  align-items: center;
}
.paginationWrapper .btnsGroup .btnWrapper {
  width: 28px;
  height: 28px;
  background-color: transparent;
  padding: 0px;
  margin-right: 6px;
  border: none;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
}
.paginationWrapper .btnsGroup .btnWrapper svg {
  width: 100%;
  height: 100%;
}
.paginationWrapper .btnsGroup .btnWrapper:hover {
  background-color: #ededed;
}
.paginationWrapper .btnsGroup .btnWrapper[disabled] {
  opacity: 0.4;
  pointer-events: none;
}

/* modal-css */
.modal__wrapper__SD {
  align-items: center;
  display: flex;
  height: 100dvh !important;
  height: 100vh;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 12;
}
.modal__wrapper__SD .modal__overlay__SD {
  background-color: #2e32341a;
  bottom: 0;
  height: 100vh;
  left: 0;
  pointer-events: none;
  position: fixed;
  right: 0;
  top: 0;
  width: 100vw;
  backdrop-filter: blur(8px);
}
.modal__wrapper__SD .modal__content__wrapper {
  background-color: #ffffff;
  border-radius: 10px;
  margin-left: auto;
  margin-right: auto;
  width: 800px;
  max-width: calc(100% - 30px);
  position: relative;
  z-index: 2;
}

.modal__wrapper__SD.dashboard .modal__content__wrapper {
  width: 1500px !important;
}

.modal__wrapper__SD .modal__header {
  align-items: center;
  background-color: #ffffff;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  box-shadow: 1px 1px 3px 0px hsla(0, 0%, 81%, 0.9),
    -1px -1px 2px 0px hsla(0, 0%, 100%, 0.9),
    1px -1px 2px 0px hsla(0, 0%, 81%, 0.2),
    -1px 1px 2px 0px hsla(0, 0%, 81%, 0.2);
  display: flex;
  justify-content: space-between;
  padding: 11px 10px 10px 15px;
  position: relative;
  z-index: 4;
}
.modal__wrapper__SD .modal__close__btn {
  width: 30px;
  height: 30px;
  background-color: transparent;
  border: none;
  position: relative;
  border-radius: 50%;
  flex-shrink: 0;
}
.modal__wrapper__SD .modal__close__btn:hover {
  background-color: #f1f1f1;
}
.modal__wrapper__SD .modal__close__btn::before,
.modal__wrapper__SD .modal__close__btn::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 16px;
  height: 2px;
  background-color: #000000;
}
.modal__wrapper__SD .modal__close__btn::before {
  transform: translate(-50%, -50%) rotate(45deg);
}
.modal__wrapper__SD .modal__close__btn::after {
  transform: translate(-50%, -50%) rotate(-45deg);
}
.modal__wrapper__SD .modal__close__btn:hover {
  background-color: #e8e8e8;
}
.modal__wrapper__SD .modal__header .title {
  color: #2e3234;
  font-size: 18px;
  padding-right: 20px;
  width: 100%;
}
.modal__wrapper__SD .modal__body {
  max-height: calc(100dvh - 225px);
  overflow-y: auto;
  padding: 26px 20px 5px;
}
/* modal-css-end */

.modal__wrapper__SD .login__form {
  box-shadow: unset;
  width: auto;
  max-width: 100%;
  margin-bottom: 20px;
}

.repeaterFieldBox {
  position: relative;
  padding-right: 52px;
}
.repeaterFieldBox .repeaterMinus__btn {
  width: 38px;
  height: 38px;
  border-radius: 50%;
  position: relative;
  background-color: #efefef;
  border: none;
  font-size: 0px;
  transition: 0.5s;
  position: absolute;
  top: 32px;
  right: 0px;
}
.repeaterFieldBox .repeaterMinus__btn::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 18px;
  height: 2px;
  z-index: 3;
  background-color: grey;
}
.repeaterFieldBox .repeaterMinus__btn:hover {
  background-color: #000000;
}
.repeaterFieldBox .repeaterMinus__btn:hover::before {
  background-color: #ffffff;
}
.addTertaryBtn {
  color: #1978b7;
  background-color: transparent;
  border: none;
}
.addTertaryBtn:hover {
  text-decoration: underline;
}
.submitBtn {
  padding-left: 20px;
  padding-right: 20px;
}

/* Add media queries for responsiveness - when the screen is 500px wide or less, stack the links on top of each other */
@media screen and (max-width: 500px) {
  .header a {
    float: none;
    display: block;
    text-align: left;
  }
  .header-right {
    float: none;
  }
}

label.form-control.disabled {
  background: #d7d4d4;
}
